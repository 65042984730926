import React, { useCallback, useContext } from "react";
import app from "../utils/firebaseApp.js";
import {
    getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendEmailVerification, signInWithPopup, GoogleAuthProvider
} from "firebase/auth";
import { AuthContext } from "../utils/auth.js";
import { withRouter } from "react-router";
import Notification from "../components/Notification.js";

import { FcGoogle } from 'react-icons/fc';

function Register() {
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [errorTitle, setErrorTitle] = React.useState("");
    const [errorSubtitle, setErrorSubtitle] = React.useState("");
    const [user, setUser] = React.useState(null)

    const provider = new GoogleAuthProvider();
    const auth = getAuth(app);

    const signInWithGoogle = () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const user = result.user;
                console.log("user >>>", user)
                setUser(user)
                // IdP data available using getAdditionalUserInfo(result)
                // ...

                // add gtm event
                try {
                    window.dataLayer.push({
                        event: "register",
                        type: "google",
                    });
                } catch (error) {

                }
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                alert(errorCode)
            });
    }

    const resetError = () => {
        setError(false);
        setErrorTitle("");
        setErrorSubtitle("");
    }

    const handleRegister = useCallback(
        async event => {
            event.preventDefault();
            const { email, password, confirm_password, terms } = event.target.elements;

            if (!terms.checked) {
                setError(true);
                setErrorTitle("Error");
                setErrorSubtitle("You must agree to the terms of service and privacy policy");

                setTimeout(() => {
                    resetError();
                }, 2000);
                return;
            }

            if (password.value !== confirm_password.value) {
                setError(true);
                setErrorTitle("Error");
                setErrorSubtitle("The password doesn't match the confirmation");

                setTimeout(() => {
                    resetError();
                }, 2000);
                return;
            }

            // if password less than 6 characters, show error
            if (password.value.length < 6) {
                setError(true);
                setErrorTitle("Error");
                setErrorSubtitle("Password must be at least 6 characters");

                setTimeout(() => {
                    resetError();
                }, 2000);
                return;
            }

            try {
                const auth = getAuth(app);
                const userCredential = await createUserWithEmailAndPassword(auth, email.value, password.value);
                resetError();

                // After creating the user, send them an email verification
                const user = userCredential.user;
                await sendEmailVerification(user);

                // add gtm event
                try {
                    window.dataLayer.push({
                        event: "register",
                        type: "email",
                    });
                } catch (error) {

                }

                window.location.href = "/";
            } catch (error) {
                console.log(error);

                setError(true);
                setErrorTitle("Error");
                setErrorSubtitle("Something went wrong");

                setTimeout(() => {
                    resetError();
                }, 2000);
            }
        },
        [window.location]
    );

    const { currentUser } = useContext(AuthContext);

    if (currentUser) {
        window.location.href = "/";
    }

    return (
        <div className="h-full bg-gray-50">
            <Notification show={error} setShow={setError} title={errorTitle} subtitle={errorSubtitle} />

            <div className="flex h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-10 w-auto"
                        src={process.env.PUBLIC_URL + '/logo.png'}
                        alt="Extracta.ai"
                    />
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                    <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                        <form className="space-y-6" onSubmit={handleRegister}>
                            <h2 className="text-left text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                Register for a new account
                            </h2>
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Email address
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        required
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    Password
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        required
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    Password confirmation
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="confirm_password"
                                        name="confirm_password"
                                        type="password"
                                        autoComplete="current-password"
                                        required
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div className="flex items-center">
                                <input
                                    id="terms"
                                    name="terms"
                                    type="checkbox"
                                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                />
                                <label htmlFor="terms" className="ml-2 block text-sm text-gray-900">
                                    {/* I have read and agree to the{' '} */}
                                    By checking this box, I confirm that I have read, understood, and agree to the{' '}
                                    <a href="https://extracta.ai/terms-and-conditions/" className="text-indigo-600 hover:text-indigo-500" target="_blank" rel="noreferrer">
                                        Terms of Use
                                    </a>{' '}
                                    and{' '}
                                    <a href="https://extracta.ai/privacy-policy/" className="text-indigo-600 hover:text-indigo-500" target="_blank" rel="noreferrer">
                                        Privacy Policy
                                    </a>
                                </label>
                            </div>

                            <div>
                                <button
                                    className="mt-10 flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Register
                                </button>
                            </div>
                        </form>

                        <div className="mb-2">
                            <div className="relative mt-5">
                                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                    <div className="w-full border-t border-gray-200" />
                                </div>
                                <div className="relative flex justify-center text-sm font-medium leading-6">
                                    <span className="bg-white px-6 text-gray-900">Or continue with</span>
                                </div>
                            </div>

                            <button onClick={signInWithGoogle} className='mt-5 flex w-full justify-center place-items-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'>
                                <FcGoogle size={20} className="mr-3" />
                                Google
                            </button>
                        </div>
                    </div>
                </div>

                <p className="mt-10 text-center text-sm text-gray-500">
                    Already have an account?{' '}
                    <a href="/login" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                        Sign in
                    </a>
                </p>
            </div>
        </div>
    );
}

export default withRouter(Register);