import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDBfYaLz3yREid2yp7gDm0Sc6TjHmw8beY",
  authDomain: "ocerizare-ro.firebaseapp.com",
  databaseURL: "https://ocerizare-ro-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "ocerizare-ro",
  storageBucket: "ocerizare-ro.appspot.com",
  messagingSenderId: "964774117274",
  appId: "1:964774117274:web:a538e3577c7875d5790423",
  measurementId: "G-76DR6DF80Q"
};


const app = initializeApp(firebaseConfig)
export default app;

export const db = getDatabase(app);
