// export libraries
import { saveAs } from 'file-saver';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import JSZip from 'jszip';

const saveFile = (data, fileName, mimeType) => {
    const blob = new Blob([data], { type: mimeType });
    saveAs(blob, fileName);
};

export const handleGenerateJson = (outputMap, fileName, fields) => {
    const json = JSON.stringify(outputMap, null, 2);
    saveFile(json, fileName + '.json', 'application/json');
};

export const handleGenerateExcel = (outputMap, fileName, fields) => {
    // Flatten the data structure for CSV format
    const flattenedData = outputMap.map(fileData => {
        const row = { file_name: fileData.file_name };
        fields.forEach(field => {
            if (fileData.data == null) fileData.data = {};
            const fieldValue = fileData.data[field.key];
            // Check if fieldValue is an object and not null
            if (typeof fieldValue === 'object' && fieldValue !== null) {
                // Convert object to JSON string
                row[field.key] = JSON.stringify(fieldValue);
            } else {
                // Use the value as is
                row[field.key] = fieldValue;
            }
        });
        return row;
    });

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    // Convert flattened data to spreadsheet
    const ws = XLSX.utils.json_to_sheet(flattenedData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Save as Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + '.xlsx');
};

export const handleGenerateCsv = (outputMap, fileName, fields) => {
    // Flatten the data structure for CSV format
    const flattenedData = outputMap.map(fileData => {
        const row = { file_name: fileData.file_name };
        fields.forEach(field => {
            if (fileData.data == null) fileData.data = {};
            const fieldValue = fileData.data[field.key];
            if (typeof fieldValue === 'object' && fieldValue !== null) {
                // Convert object to JSON string and escape double quotes
                row[field.key] = JSON.stringify(fieldValue).replace(/"/g, '""');
            } else {
                // Convert to string and escape double quotes
                // row[field.key] = `"${fieldValue}"`.replace(/"/g, '""');
                row[field.key] = fieldValue;
            }
        });
        return row;
    });

    // Extract headers from flattened data
    const headers = Object.keys(flattenedData.reduce((acc, curr) => ({ ...acc, ...curr }), {}));
    const csvRows = [];

    // Add the headers to the csvRows array
    csvRows.push(headers.join(','));

    // Convert the flattened data to CSV rows
    flattenedData.forEach(row => {
        csvRows.push(headers.map(header => `"${row[header] ? row[header] : ""}"`).join(','));
    });

    // Convert the rows array to a single CSV string
    const csvString = csvRows.join('\n');

    saveFile(csvString, fileName + '.csv', 'text/csv;encoding:utf-8');
};

// export const handleGenerateWord = async (outputArray, fileName) => {
//     let index = 1;

//     await outputArray.forEach(async (output) => {
//         console.log(index);
//         console.log(output);

//         let newFileName = fileName + `-${index}.docx`;
//         const paragraphs = output.data["paragraphs"];

//         if (outputArray.length === 1) {
//             newFileName = fileName + '.docx';
//         }

//         // Create the paragraph
//         const paragraphsList = [];

//         paragraphs.forEach(paragraph => {
//             const newParagraph = new Paragraph({
//                 children: [new TextRun(paragraph)],
//             });
//             const emptyParagraph = new Paragraph({});

//             paragraphsList.push(newParagraph);
//             paragraphsList.push(emptyParagraph);
//         });

//         // Create the document with sections
//         console.log(newFileName);
//         const doc = new Document({
//             creator: "Extracta.ai",
//             title: fileName,
//             description: "Extracta.ai Document",
//             sections: [
//                 {
//                     properties: {},
//                     children: paragraphsList,
//                 },
//             ],
//         });

//         // Generate a Blob from the document and trigger the download
//         await Packer.toBlob(doc)
//             .then((blob) => {
//                 saveAs(blob, newFileName);
//             })
//             .catch((error) => {
//                 console.error("Error exporting file:", error);
//                 throw error;
//             });

//         // wait 5 seconds
//         console.log("before wait");
//         await new Promise(resolve => setTimeout(resolve, 2000));
//         console.log("after wait");

//         index++;
//     });
// }

// export const handleGenerateWord = (outputArray, fileName) => {
//     let index = 1;
//     const zip = new JSZip();

//     const docPromises = outputArray.map((output) => {
//         let newFileName = fileName + `-${index}.docx`;
//         const paragraphs = output.data["paragraphs"];

//         if (outputArray.length === 1) {
//             newFileName = fileName + '.docx';
//         }

//         index++;

//         // Create the paragraphs list
//         const paragraphsList = [];

//         paragraphs.forEach(paragraph => {
//             const newParagraph = new Paragraph({
//                 children: [new TextRun(paragraph)],
//             });
//             const emptyParagraph = new Paragraph({});

//             paragraphsList.push(newParagraph);
//             paragraphsList.push(emptyParagraph);
//         });

//         // Create the document with sections
//         const doc = new Document({
//             creator: "Extracta.ai",
//             title: fileName,
//             description: "Extracta.ai Document",
//             sections: [
//                 {
//                     properties: {},
//                     children: paragraphsList,
//                 },
//             ],
//         });

//         // Generate a Buffer from the document and add it to the zip
//         return Packer.toBuffer(doc)
//             .then((buffer) => {
//                 zip.file(newFileName, buffer);
//             })
//             .catch((error) => {
//                 console.error("Error exporting file:", error);
//                 throw error;
//             });
//     });

//     // After all documents are added to the zip, generate the zip file and trigger download
//     Promise.all(docPromises).then(() => {
//         zip.generateAsync({ type: 'blob' }).then((content) => {
//             saveAs(content, fileName + '.zip');
//         });
//     });
// };

// export const handleGenerateWord = async (outputArray, fileName) => {
//     let index = 1;

//     for (const output of outputArray) {
//         let newFileName = fileName + `-${index}.docx`;
//         const paragraphs = output.data["paragraphs"];

//         if (outputArray.length === 1) {
//             newFileName = fileName + '.docx';
//         }

//         // Create the paragraphs list
//         const paragraphsList = [];

//         paragraphs.forEach(paragraph => {
//             const newParagraph = new Paragraph({
//                 children: [new TextRun(paragraph)],
//             });
//             const emptyParagraph = new Paragraph({});

//             paragraphsList.push(newParagraph);
//             paragraphsList.push(emptyParagraph);
//         });

//         // Create the document with sections
//         const doc = new Document({
//             creator: "Extracta.ai",
//             title: fileName,
//             description: "Extracta.ai Document",
//             sections: [
//                 {
//                     properties: {},
//                     children: paragraphsList,
//                 },
//             ],
//         });

//         // Generate a Blob from the document and trigger the download
//         try {
//             console.log(newFileName);
//             const blob = await Packer.toBlob(doc);
//             saveAs(blob, newFileName);
//         } catch (error) {
//             console.error("Error exporting file:", error);
//             throw error;
//         }

//         index++;
//     }
// };

export const handleGenerateWord = (outputArray, fileName) => {
    if (outputArray.length === 1) {
        // Single document case
        const output = outputArray[0];
        const paragraphs = output.data["paragraphs"];

        // Create the paragraphs list
        const paragraphsList = [];

        paragraphs.forEach((paragraph) => {
            const newParagraph = new Paragraph({
                children: [new TextRun(paragraph)],
            });
            const emptyParagraph = new Paragraph({});

            paragraphsList.push(newParagraph);
            paragraphsList.push(emptyParagraph);
        });

        // Create the document with sections
        const doc = new Document({
            creator: "Extracta.ai",
            title: fileName,
            description: "Extracta.ai Document",
            sections: [
                {
                    properties: {},
                    children: paragraphsList,
                },
            ],
        });

        // Generate a Blob from the document and trigger the download
        Packer.toBlob(doc)
            .then((blob) => {
                saveAs(blob, `${fileName}.docx`);
            })
            .catch((error) => {
                console.error("Error exporting file:", error);
            });
    } else {
        let index = 1;
        const zip = new JSZip();

        const docPromises = outputArray.map((output) => {
            const paragraphs = output.data["paragraphs"];
            const fileNameFile = output.file_name;

            let newFileName = `${fileNameFile}-${index}.docx`;

            index++;

            // Create the paragraphs list
            const paragraphsList = [];

            paragraphs.forEach((paragraph) => {
                const newParagraph = new Paragraph({
                    children: [new TextRun(paragraph)],
                });
                const emptyParagraph = new Paragraph({});

                paragraphsList.push(newParagraph);
                paragraphsList.push(emptyParagraph);
            });

            // Create the document with sections
            const doc = new Document({
                creator: "Extracta.ai",
                title: fileName,
                description: "Extracta.ai Document",
                sections: [
                    {
                        properties: {},
                        children: paragraphsList,
                    },
                ],
            });

            // Generate a Blob from the document
            return Packer.toBlob(doc)
                .then((blob) => {
                    // Convert the Blob to ArrayBuffer
                    return blob.arrayBuffer().then((arrayBuffer) => {
                        // Add the ArrayBuffer to the ZIP file
                        zip.file(newFileName, arrayBuffer);
                    });
                })
                .catch((error) => {
                    console.error("Error exporting file:", error);
                    throw error;
                });
        });

        // After all documents are added to the ZIP, generate the ZIP file and trigger download
        Promise.all(docPromises).then(() => {
            zip
                .generateAsync({ type: 'blob' })
                .then((content) => {
                    saveAs(content, `${fileName}.zip`);
                })
                .catch((error) => {
                    console.error("Error generating ZIP file:", error);
                });
        });
    }
};