import React from "react";
import { useState, useEffect, useContext } from 'react'

import { getAuth } from "firebase/auth";
import { get, ref, query, startAt, endAt, orderByChild, onValue } from "firebase/database";

import { db } from '../utils/firebaseApp.js';
import app from "../utils/firebaseApp.js";

import { AuthContext } from "../utils/auth.js";

// import MenuBar from '../components/MenuBar.js';

import { BarChart, DonutChart, AreaChart } from "@tremor/react";

import { Fragment } from 'react'

import { Dialog, Menu, Transition } from '@headlessui/react'

import {
    Bars3Icon,
    BellIcon,
    CalendarIcon,
    ChartPieIcon,
    Cog6ToothIcon,
    DocumentDuplicateIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'

import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'

import { PlusIcon } from '@heroicons/react/20/solid'

import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    Text,
} from "@tremor/react";

import CreditsComponent from "./Credits.js";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TopBar({ setSidebarOpen }) {
    const environment = process.env.REACT_APP_ENV;

    const userNavigation = [
        { name: 'Your profile', href: '/account' },
    ]

    const handleSignout = () => {
        getAuth(app).signOut(app).then(() => {
            // console.log("Signout")
        }).catch((error) => {
            // console.log(error)
        });
    }

    const { currentUser } = useContext(AuthContext);

    return (
        <div className="hidden sticky top-0 z-50 lg:flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <div className="flex flex-1 justify-end gap-x-4 self-stretch lg:gap-x-6">
                <div className="flex items-center gap-x-4 lg:gap-x-4">
                    {/* Credits */}
                    <CreditsComponent />

                    {/* Separator */}
                    <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />

                    {/* Profile dropdown */}
                    <Menu as="div" className="relative">
                        <Menu.Button className="-m-1.5 flex items-center p-1.5">
                            <span className="sr-only">Open user menu</span>
                            {/* <img
                                className="h-8 w-8 rounded-full bg-gray-50"
                                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                alt=""
                            /> */}
                            <span className="hidden lg:flex lg:items-center">
                                <span className="ml-4 text-sm font-semibold leading-6 text-gray-500" aria-hidden="true">
                                    {currentUser.email}
                                </span>
                                <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </Menu.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 z-0 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                {userNavigation.map((item) => (
                                    <Menu.Item key={item.name}>
                                        {({ active }) => (
                                            <a
                                                href={item.href}
                                                className={classNames(
                                                    active ? 'bg-gray-50' : '',
                                                    'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                )}
                                            >
                                                {item.name}
                                            </a>
                                        )}
                                    </Menu.Item>
                                ))}
                                <Menu.Item key='logout'>
                                    {({ active }) => (
                                        <button
                                            onClick={() => handleSignout()}
                                            className={classNames(
                                                active ? 'bg-gray-50' : '',
                                                'w-full text-left block px-3 py-1 text-sm leading-6 text-gray-900'
                                            )}
                                        >
                                            Log out
                                        </button>
                                    )}
                                </Menu.Item>
                            </Menu.Items>
                        </Transition>
                    </Menu>

                    {(environment === "development" || currentUser.uid === "WgVLxXREk7eW2aQeFOSfRbuxJbm1") && <ShowUserId currentUser={currentUser} />}
                    {environment === "test" && <ShowUserId currentUser={currentUser} />}
                </div>
            </div>
        </div>
    );
}

const ShowUserId = ({ currentUser }) => {
    return (
        <>
            {/* Separator */}
            <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />

            {/* show user id */}
            <div className="hidden lg:flex lg:items-center">
                <span className="ml-4 text-sm font-semibold leading-6 text-gray-500" aria-hidden="true">
                    {currentUser.uid}
                </span>
            </div>
        </>
    );
}