import { Fragment, useState, useEffect, useContext } from 'react'
import { Transition } from '@headlessui/react'

import app from "../utils/firebaseApp.js";
import { db } from '../utils/firebaseApp.js';
import { getAuth } from "firebase/auth";
import { AuthContext } from "../utils/auth.js";
import { get, set, ref } from "firebase/database";

import MenuBar from '../components/MenuBar.js';

import { XMarkIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'

import TopBar from "../components/TopBar.js";

export default function Account() {
  const { currentUser } = useContext(AuthContext);
  let userId = currentUser.uid;
  let userEmail = currentUser.email;

  const [sidebarOpen, setSidebarOpen] = useState(false)

  const handleSignout = () => {
    getAuth(app).signOut(app).then(() => {
      // console.log("Signout")
    }).catch(() => {
      // console.log(error)
    });
  }

  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [accountObject, setAccountObject] = useState(null);

  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submit behavior
    setIsLoading(true);

    // Create an object with the data
    const updatedUserDetails = {
      fullName,
      phoneNumber,
      emailAddress: userEmail,
      updatedAt: new Date().toISOString()
    };

    // Reference to the user's database path
    const databaseRef = ref(db, 'userDetails/' + userId + '/account');

    // Set the data in the database
    try {
      await set(databaseRef, updatedUserDetails);
      showSuccessFunction();
    } catch (error) {
      showErrorFunction();
    }

    setIsLoading(false);
  };

  const formatPhoneNumber = (value) => {
    // If the input is not a number, return the original value
    if (isNaN(value.replace(/ /g, ''))) {
      return phoneNumber; // Return the previous phone number
    }

    // Remove all non-digits
    value = value.replace(/\D/g, '');

    // Add spaces for the format 'XXXX XXX XXX'
    if (value.length > 4) {
      value = value.substring(0, 4) + ' ' + value.substring(4);
    }
    if (value.length > 8) {
      value = value.substring(0, 8) + ' ' + value.substring(8);
    }

    return value;
  };

  const handlePhoneNumberChange = (e) => {
    const { value } = e.target;
    const formatted = formatPhoneNumber(value);

    // Limit the formatted length to 12 (including spaces)
    if (formatted.length <= 12) {
      setPhoneNumber(formatted);
    }
  };

  const showErrorFunction = () => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 2000);
  }

  const showSuccessFunction = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 2000);
  }

  const fetchUserDetails = async () => {
    const databaseRef = ref(db, 'userDetails/' + userId + '/account');

    try {
      const snapshot = await get(databaseRef);

      if (snapshot.exists()) {
        const data = snapshot.val();

        setAccountObject(data);

        if (data.fullName === undefined) {
          setFullName("");
        } else {
          setFullName(data.fullName);
        }

        if (data.phoneNumber === undefined) {
          setPhoneNumber("");
        } else {
          setPhoneNumber(data.phoneNumber);
        }
      } else {
        console.log("No data available");
        setAccountObject({});
      }
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    const appName = process.env.REACT_APP_NAME;
    document.title = "Account - " + appName;

    const fetchData = async () => {
      await fetchUserDetails();
    }
    fetchData();
  }, [userId]);

  return (
    <>
      <ErrorNotification showError={showError} setShowError={setShowError} />
      <GoodNotification showError={showSuccess} setShowError={setShowSuccess} />

      <div>
        <MenuBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} handleSignout={handleSignout} currentHref="account" />

        <main className="lg:pl-72">
          <TopBar setSidebarOpen={setSidebarOpen} handleSignout={handleSignout} />

          <div className="py-10 px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col justify-center items-start">
              <div className='px-3'>
                <a href='/account' className="text-4xl font-bold text-gray-900">Account</a>
                <p className="text-m text-gray-500"></p>
              </div>
            </div>

            <div className='w-full px-3 mt-10'>
              <AccountDetails
                userEmail={userEmail}
                accountObject={accountObject}
                fullName={fullName}
                phoneNumber={phoneNumber}
                handleFormSubmit={handleFormSubmit}
                setFullName={setFullName}
                setPhoneNumber={setPhoneNumber}
                handlePhoneNumberChange={handlePhoneNumberChange}
                isLoading={isLoading}
              />
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

function AccountDetails({ userEmail, accountObject, fullName, phoneNumber, handleFormSubmit, setFullName, setPhoneNumber, handlePhoneNumberChange, isLoading }) {
  if (accountObject === null) return (
    <div className="flex-1 flex items-center justify-center h-32">
      <div role="status">
        <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="space-y-12">
        <div className="pb-3">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Personal Information</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">Help us stay in touch by keeping your details up to date.</p>

          <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">

            <div className="sm:col-span-6">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                E-mail
              </label>
              <div className="mt-2">
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={userEmail}
                  disabled
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
                  placeholder="you@example.com"
                />
              </div>
            </div>

            <div className="sm:col-span-6">
              <div className="flex justify-between">
                <label htmlFor="full-name" className="block text-sm font-medium leading-6 text-gray-900">
                  Full name
                </label>
                <span className="text-sm leading-6 text-gray-400">
                  {fullName.length}/50
                </span>
              </div>
              <div className="mt-2">
                <input
                  type="text"
                  name="full-name"
                  id="full-name"
                  autoComplete="given-name"
                  maxLength={50}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  placeholder="John Doe"
                />
              </div>
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="phone-number" className="block text-sm font-medium leading-6 text-gray-900">
                Phone Number
              </label>
              <div className="relative mt-2 rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 flex items-center">
                  <label htmlFor="country" className="sr-only">
                    Country
                  </label>
                  <select
                    id="country"
                    name="country"
                    autoComplete="country"
                    className="h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                  >
                    <option>RO</option>
                  </select>
                </div>
                <input
                  type="text"
                  name="phone-number"
                  id="phone-number"
                  className="block w-full rounded-md border-0 py-1.5 pl-16 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="0711 111 111"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  required={phoneNumber.length !== 0}
                  pattern="[\d\s]{12}"
                  title="Phone number must have exactly 10 digits"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          {isLoading ? "Saving..." : "Save"}
        </button>
      </div>
    </form>
  )
}

function GoodNotification({ showError, setShowError }) {
  const successfulTitle = 'Save Successful!';
  const successfulSubtitle = 'Your details have been saved successfully.';

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="z-50 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={showError}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{successfulTitle}</p>
                    <p className="mt-1 text-sm text-gray-500">{successfulSubtitle}</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setShowError(false)
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}

function ErrorNotification({ showError, setShowError }) {
  const errorTitle = 'Save Failed!';
  const errorSubtitle = 'There was an issue saving your details. Please try again.';

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="z-50 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={showError}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <ExclamationCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{errorTitle}</p>
                    <p className="mt-1 text-sm text-gray-500">{errorSubtitle}</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setShowError(false)
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}