/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useLayoutEffect, useRef, useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom';
import { Menu, Dialog, Transition } from '@headlessui/react'
import { get, ref, onValue, startAt, orderByKey, limitToFirst, query } from "firebase/database";
import axios from "axios";
import ReactJson from 'react18-json-view';
import 'react18-json-view/src/style.css'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ArrowLongLeftIcon, ArrowLongRightIcon, ChevronDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel, faFileCsv, faCode, faRefresh, faEdit as IconEdit } from '@fortawesome/free-solid-svg-icons'
import { PlusIcon, TrashIcon } from '@heroicons/react/20/solid'
import { CalendarIcon, DocumentTextIcon, GlobeAltIcon, AdjustmentsHorizontalIcon, CodeBracketIcon, CodeBracketIcon as CustomCodeIcon } from '@heroicons/react/24/outline';
import { PencilIcon } from '@heroicons/react/20/solid'

import { AuthContext } from "../../../utils/auth.js";
import CreateExtraction from '../../../components/CreateExtraction.js';
import ViewFile from '../../../components/ViewFile.js';
import ModalForNewExtraction from '../../../components/utils/ModalForNewExtraction.js';
import ModalForViewFile from '../../../components/utils/ModalForViewFile.js';
import { handleGenerateJson, handleGenerateExcel, handleGenerateCsv } from '../../../components/functions/exportData.js';
import { db } from '../../../utils/firebaseApp.js';
import Breadcrumb from '../../../components/Breadcrumb';

function classNames(...classes) { return classes.filter(Boolean).join(' ') }

function JsonViewerWindow({ jsonData }) {
    // State to control the visibility of the modal
    const [isModalOpen, setModalOpen] = useState(false);

    // Function to handle outside click
    const handleOutsideClick = (e) => {
        if (e.target === e.currentTarget) {
            setModalOpen(false);
        }
    };

    const jsonViewerStyle = {
        maxHeight: '80vh', // Maximum height of the container
        overflowY: 'auto', // Enable vertical scrolling
        wordBreak: 'break-all', // Breaks long words to prevent overflow
    };

    return (
        <div className="">
            {/* Button with only icon, small, to trigger the modal */}
            <button
                onClick={() => setModalOpen(true)}
                className="inline-flex items-center justify-center h-6 w-6 rounded-full bg-gray-100 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                <FontAwesomeIcon icon={faCode} />
            </button>

            {/* Modal content */}
            {isModalOpen && (
                <div
                    onClick={handleOutsideClick}
                    className="fixed inset-0 flex items-center justify-center z-50"
                >
                    <div
                        onClick={(e) => e.stopPropagation()}
                        className="bg-white rounded-lg w-1/3 p-6 relative border border-gray-200 shadow-lg"
                        style={jsonViewerStyle}
                    >
                        {/* Close icon */}
                        <span
                            onClick={() => setModalOpen(false)}
                            className="absolute top-4 right-4 cursor-pointer text-xl font-bold hover:text-gray-600"
                        >
                            &times;
                        </span>
                        <ReactJson
                            src={jsonData}
                            theme="vscode"
                            enableClipboard={false}
                            displayDataTypes={false}
                            displayObjectSize={false}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default JsonViewerWindow;