import TaskIcon from '@mui/icons-material/Task';


export default function FileList({ files, isUpload, percentages, removeFile }) {
  return (
    <ul className="divide-y divide-gray-100">
      {files.map((item, index) => (
        <li key={index} className="py-4">
          <div className="flex items-center gap-x-3">
            <TaskIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
            <h3 className="flex-auto truncate text-sm font-semibold leading-6 text-gray-900">{item.name}</h3>
            <div className="flex-none text-xs text-gray-500">
              {!isUpload ?
                <button onClick={() => removeFile(index)}>
                  <span className='hover:text-red-400'>remove</span>
                  </button>
                :
                percentages[item] + '%'
              }
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
}
