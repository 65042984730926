import { useState, useContext, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom';

import { get, ref } from "firebase/database";
import { db } from '../utils/firebaseApp.js';
import { AuthContext } from "../utils/auth.js";

import Steps from './utils/Steps.js';
import FirstStep from './createExtractionsSteps/FirstStep.js';
import SecondStep from './createExtractionsSteps/SecondStep.js';
import ThirdStep from './createExtractionsSteps/ThirdStep.js';
import FourthStep from './createExtractionsSteps/FourthStep.js';
import SaveExtraction from './createExtractionsSteps/SaveExtraction.js';
import TemplateStep from './createExtractionsSteps/TemplateStep.js';


const status = {
    current: 'current',
    complete: 'complete',
    upcoming: 'upcoming',
}

const CreateExtraction = ({ isEdit, closeModal, extractionsLength }) => {
    const { currentUser } = useContext(AuthContext);
    const { extractionId, batchId } = useParams();

    const onlyBatch = extractionId != null;
    const moreFiles = batchId != null;
    const isEditMode = isEdit ?? false;

    let stepsOnlyBatch = [
        { name: 'Step 3', href: '#', status: 'current' },
        { name: 'Step 4', href: '#', status: 'upcoming' },
    ]

    let stepsStandard = [
        { name: 'Step 1', href: '#', status: 'current' },
        { name: 'Step 2', href: '#', status: 'upcoming' },
        { name: 'Step 3', href: '#', status: 'upcoming' },
        { name: 'Step 4', href: '#', status: 'upcoming' },
        { name: 'Step 5', href: '#', status: 'upcoming' },
    ]

    let stepsMoreFiles = [
        { name: 'Step 3', href: '#', status: 'current' },
        { name: 'Step 4', href: '#', status: 'upcoming' },
    ]

    let editSteps = [
        { name: 'Step 1', href: '#', status: 'current' },
        { name: 'Step 2', href: '#', status: 'upcoming' },
        { name: 'Step 4', href: '#', status: 'upcoming' },
    ]

    const [steps, setSteps] = useState(isEditMode ? editSteps : onlyBatch ? stepsOnlyBatch : stepsStandard);
    const [currentStep, setCurrentStep] = useState(steps[0].name);
    const [params, setStateParams] = useState(moreFiles ? { extractionId, batchId } : {
        extractionsLength: extractionsLength,
    });

    const fetchExtraction = async () => {
        let userId = currentUser.uid;
        const databaseRef = ref(db, 'extractions/' + userId + '/' + extractionId);
        try {
            const snapshot = await get(databaseRef);
            if (snapshot.exists()) {
                const data = snapshot.val();
                loadExtraction(data);
                return data;
            } else {
                //
            }
        } catch (error) {
        }
    };

    const loadExtraction = (extractionData) => {
        if (extractionData != null) {
            let newParams = {
                extractionID: extractionId,
                fields: extractionData.fields,
                options: extractionData.options,
                lang: extractionData.language,
                name: extractionData.name,
                description: extractionData.description,
                workflowId: extractionData.workflowId,
            }
            setParams(newParams);
        }
    }

    useEffect(() => {
        if (extractionId != null) {
            fetchExtraction();
        }
    }, [extractionId]);

    const setParams = (newParams) => {
        setStateParams({ ...params, ...newParams });
    }

    const previousStep = (newParams) => {
        if (newParams != null) {
            setParams(newParams);
        }

        let currentStepNumber = steps.findIndex(step => step.status === status.current);
        goToStepNumber(currentStepNumber - 1);
    }

    const nextStep = (newParams) => {
        setParams(newParams);

        if (newParams.closeModal) {
            closeModal(newParams.extractionId);
            return;
        }

        for (let i = 0; i < steps.length; i++) {
            if (steps[i].status === status.current) {
                steps[i].status = status.complete;
                steps[i + 1].status = status.current;
                setCurrentStep(steps[i + 1].name);
                break;
            }
        }
    }

    const goToStepNumber = (stepNumber) => {
        if (stepNumber < steps.findIndex(step => step.status === status.current)) {
            for (let i = 0; i < steps.length; i++) {
                if (i === stepNumber) {
                    steps[i].status = status.current;
                    setCurrentStep(steps[i].name);
                } else if (i > stepNumber) {
                    steps[i].status = status.upcoming;
                } else {
                    steps[i].status = status.complete;
                }
            }
        }
    }

    const divRef = useRef(null);
    const [isScrollable, setIsScrollable] = useState(false);

    useEffect(() => {
        function checkScrollable() {
            if (divRef.current.scrollHeight > divRef.current.clientHeight) {
                setIsScrollable(true);
            } else {
                setIsScrollable(false);
            }
        }

        // Set up MutationObserver
        const observer = new MutationObserver(checkScrollable);

        if (divRef.current) {
            // Initialize the observer
            observer.observe(divRef.current, { childList: true, subtree: true });
        }

        // Initial check
        checkScrollable();

        return () => {
            // Disconnect observer when component is unmounted or before setting up a new one
            observer.disconnect();
        };

    }, []); // Keeping the dependency array empty ensures the effect runs once after mount and the cleanup runs on unmount.


    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <div className='h-full flex flex-col items-start pt-5'>
            <div className='w-full mr-2 px-16 pt-3 pb-6'>
                <Steps steps={steps} goToStepNumber={goToStepNumber} />
            </div>
            <div ref={divRef} className={classNames('w-full mr-1 px-10 overflow-y-auto', isScrollable ? '' : 'flex flex-col justify-center h-full')}>
                {
                    <div className='px-4 pb-4 sm:p-6'>
                        {(
                            isEditMode ? {
                                [steps[0].name]: <FirstStep key={0} nextStep={nextStep} parentParams={params} isEdit={isEdit} />,
                                [steps[1].name]: <SecondStep key={1} nextStep={nextStep} previousStep={previousStep} parentParams={params} isEdit={isEdit} />,
                                [steps[2].name]: <SaveExtraction key={3} nextStep={nextStep} previousStep={previousStep} params={params} hasExtractionId={true} />,
                            } :
                                onlyBatch ? {
                                    [steps[0].name]: <ThirdStep key={2} nextStep={nextStep} parentParams={params} />,
                                    [steps[1].name]: <FourthStep key={3} nextStep={nextStep} previousStep={previousStep} params={params} hasExtractionId={true} />,
                                }
                                    :
                                    moreFiles ?
                                        {
                                            [steps[0].name]: <ThirdStep key={2} nextStep={nextStep} parentParams={params} />,
                                            [steps[1].name]: <FourthStep key={3} nextStep={nextStep} previousStep={previousStep} params={params} hasExtractionId={true} />,
                                        } :
                                        {
                                            [steps[0].name]: <TemplateStep key={1} nextStep={nextStep} previousStep={previousStep} parentParams={params} />,
                                            [steps[1].name]: <FirstStep key={0} nextStep={nextStep} previousStep={previousStep} parentParams={params} />,
                                            [steps[2].name]: <SecondStep key={2} nextStep={nextStep} previousStep={previousStep} parentParams={params} />,
                                            [steps[3].name]: <ThirdStep key={3} nextStep={nextStep} previousStep={previousStep} parentParams={params} />,
                                            [steps[4].name]: <FourthStep key={4} nextStep={nextStep} previousStep={previousStep} params={params} hasExtractionId={false} />,
                                        }
                        )[currentStep]}
                    </div>
                }
            </div>
        </div>
    )
}


export default CreateExtraction;