import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Breadcrumb({ pages }) {
    return (
        <nav className="flex" aria-label="Breadcrumb">
            <ol className="flex items-center space-x-4">
                {/* <li>
                    <div>
                        <a className="text-gray-400 hover:text-gray-500">
                            <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                            <span className="sr-only">Home</span>
                        </a>
                    </div>
                </li> */}
                <li className="flex h-11">
                    <div className="flex items-center">
                        <a
                            href="/extractions"
                            className="text-sm font-medium text-gray-500 hover:text-gray-700"
                        >
                            Extractions
                        </a>
                    </div>
                </li>
                {pages.map((page, index) => (
                    <li key={index}>
                        <div className="flex items-center">
                            {/* <svg
                                className="h-5 w-5 flex-shrink-0 text-gray-300"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                            >
                                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                            </svg> */}
                            <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />

                            <a
                                href={page.href}
                                // className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                aria-current={page.current ? 'page' : undefined}
                                className={classNames(page.current ? "text-indigo-500 hover:text-indigo-700" : "", "ml-4 text-sm font-medium text-gray-500 hover:text-gray-700")}
                            >
                                {page.name}
                            </a>
                        </div>
                    </li>
                ))}
            </ol>
        </nav>
    )
}

function Breadcrumb2({ pages }) {
    return (
        <nav className="flex mb-5" aria-label="Breadcrumb">
            <ol className="flex space-x-4 rounded-md bg-white px-6 shadow">
                <li className="flex h-11">
                    <div className="flex items-center">
                        <a
                            href="/extractions"
                            className="text-sm font-medium text-gray-500 hover:text-gray-700"
                        >
                            Extractions
                        </a>
                    </div>
                </li>
                {pages.map((page, index) => (
                    <li key={index} className="flex">
                        <div className="flex items-center">
                            <svg
                                className="h-full w-6 flex-shrink-0 text-gray-200"
                                viewBox="0 0 24 44"
                                preserveAspectRatio="none"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                            </svg>
                            <a
                                href={page.href}
                                className={classNames(page.current ? "text-indigo-500 hover:text-indigo-700" : "", "ml-4 text-sm font-medium text-gray-500 hover:text-gray-700")}
                            >
                                {page.name}
                            </a>
                        </div>
                    </li>
                ))}
            </ol>
        </nav>
    )
}

export default Breadcrumb;