import { useState, useContext, useEffect } from "react";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import app from "../../utils/firebaseApp.js";
import { AuthContext } from "../../utils/auth.js";
import axios from "axios";
import async from "async";
import ShortUniqueId from 'short-unique-id';
import FileList from "../utils/FileList.js";

import { pdfjs } from 'react-pdf';
import Lottie from 'react-lottie';
import uploadSuccessfulData from './upload_successful.json';
import loadingData from './loading.json';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const { randomUUID } = new ShortUniqueId({ length: 10 });

export default function FourthStep({ params, previousStep, hasExtractionId, nextStep }) {
    const { currentUser } = useContext(AuthContext);

    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const environment = process.env.REACT_APP_ENV;

    const hasBatch = params.batchId != null;
    const [percentages, setPercentages] = useState([]);
    const [startedUpload, setStartedUpload] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [noOfPages, setNoOfPages] = useState(0);
    const storage = getStorage(app);

    const firstExtractionTask = async () => {
        await currentUser.getIdToken().then(async (idToken) => {
            const url = serverUrl + "/firstExtraction";

            await axios.post(url, {
                // nothing
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Bearer ${idToken}`
                }
            }).then((response) => {
                // nothing
            }).catch((error) => {
                // nothing
            });
        });
    }

    const createExtraction = async (callback) => {
        await currentUser.getIdToken().then(async (idToken) => {
            const url = serverUrl + "/createExtraction";

            await axios.post(url, {
                "extractionDetails": {
                    "name": params.name,
                    "description": params.description,
                    "language": params.lang,
                    "fields": params.fields,
                    "options": params.options,
                    "workflowId": params.workflowId,
                    "createdAt": Date.now(),
                }
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Bearer ${idToken}`
                }
            }).then(async (response) => {
                if (params.extractionsLength === 0) {
                    window.dataLayer.push({
                        event: "extraction_created",
                        extractionID: response.data.extractionID,
                        extractionName: params.name,
                        extractionLanguage: params.lang,
                    });
                    await firstExtractionTask();
                }

                callback(response.data.extractionId);
            }).catch((error) => {
            });
        });
    }

    const createBatch = () => {
        if (hasBatch) {
            let extractionId = params.extractionID;
            let batchId = params.batchId;

            setStartedUpload(true);
            const formData = new FormData();
            formData.append("extractionId", extractionId);
            formData.append("batchId", batchId);

            let newPercentages = {}
            params.files.forEach((file) => {
                formData.append("files", file);
                newPercentages[file] = 0;
            })

            setPercentages(newPercentages);

            currentUser.getIdToken().then((idToken) => {
                const url = serverUrl + "/addMoreFiles";
                axios.post(url, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Access-Control-Allow-Origin": "*",
                        Authorization: `Bearer ${idToken}`
                    }
                }).then((response) => {
                    setIsFinished(true);
                    setTimeout(() => {
                        // set percentages to 100 for all files
                        let newPercentages = [];
                        for (let i = 0; i < params.files.length; i++) {
                            newPercentages.push(100);
                        }
                        params.files.forEach((file) => {
                            newPercentages[file] = 100;
                        })
                        setPercentages(newPercentages);
                        nextStep({ closeModal: true });
                    }, 1000);
                }).catch((error) => {
                    if (error.response.data === "not_enough_credits") {
                        setErrorMessage("You don't have enough credits to perform this extraction.\nPlease buy more credits to continue.");
                    } else if (error.response.data.message === "Not enough credits") {
                        setErrorMessage("You don't have enough credits to perform this extraction.\nPlease buy more credits to continue.");
                    }
                    setIsError(true);
                    setIsFinished(true);
                });
            });
            return;
        }

        if (hasExtractionId) {
            let extractionID = params.extractionID;
            const batchId = randomUUID();
            setStartedUpload(true);
            async.eachLimit(params.files, 5, (file, callback) => {
                let fileName = randomUUID() + "_%_" + file.name;
                uploadFile(file, fileName, batchId, callback);
            }, (err) => {
                if (err) {
                } else {
                    startExtraction(extractionID, batchId, (err) => {
                        if (err) {
                            setIsError(true);
                            setIsFinished(true);

                            if (err.response.data === "not_enough_credits") {
                                setErrorMessage("You don't have enough credits to perform this extraction.\nPlease buy more credits to continue.");
                            } else if (err.response.data.nessage === "Not enough credits") {
                                setErrorMessage("You don't have enough credits to perform this extraction.\nPlease buy more credits to continue.");
                            }
                        } else {
                            setIsFinished(true);
                            setErrorMessage("");
                            setIsError(false);

                            setTimeout(() => {
                                nextStep({ closeModal: true });
                            }, 1000);
                        }
                    });
                }
            });
        } else {
            createExtraction((extractionID) => {
                const batchId = randomUUID();
                setStartedUpload(true);
                async.eachLimit(params.files, 5, (file, callback) => {
                    let fileName = randomUUID() + "_%_" + file.name;
                    uploadFile(file, fileName, batchId, callback);
                }, (err) => {
                    if (err) {
                    } else {
                        startExtraction(extractionID, batchId, (err) => {
                            if (err) {
                                console.log("err");
                                console.log(err);
                                console.log("err");

                                setIsError(true);
                                setIsFinished(true);
                            } else {
                                setIsFinished(true);
                                setTimeout(() => {
                                    nextStep({ closeModal: true, extractionId: extractionID });
                                }, 1000);
                            }
                        });
                    }
                });
            });
        }
    }

    const startExtraction = (extractionId, batchId, callback) => {
        currentUser.getIdToken().then((idToken) => {
            const url = serverUrl + "/uploadBatch";

            axios.post(url, {
                "extractionId": extractionId,
                "batchId": batchId,
                "fields": params.fields,
                "language": params.lang,
                "workflowId": params.workflowId,
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Bearer ${idToken}`
                }
            }).then((response) => {
                callback();
            }).catch((error) => {
                callback(error);
            });
        });
    }

    const uploadFile = (file, fileName, batchId, callback) => {
        const storageRef = ref(storage, `/extractions/${currentUser.uid}/${batchId}/${fileName}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                let newPersentages = [...percentages];
                newPersentages[file] = percent;
                setPercentages(newPersentages);
            },
            (err) => { callback(err); },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    currentUser.getIdToken().then((idToken) => { });
                    callback();
                });
            }
        );
    };

    const WORDS_PER_PAGE = 500;

    const getNoOfPagesFromTxt = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                const content = event.target.result;
                const words = content.trim().split(/\s+/);
                const numberOfWords = words.length;
                const numberOfPages = Math.ceil(numberOfWords / WORDS_PER_PAGE);
                resolve(numberOfPages);
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsText(file);
        });
    };

    const getNoOfPagesFromPDF = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = async () => {
                try {
                    const arrayBuffer = reader.result;
                    const pdf = await pdfjs.getDocument(arrayBuffer).promise;
                    resolve(pdf.numPages);
                } catch (error) {
                    reject(error);
                }
            };
            reader.onerror = error => reject(error);
        });
    };

    const getNoOfPagesFromWord = async (file) => {
        return 1;
    }

    useEffect(() => {
        const fetchNumberOfPages = async () => {
            if (params.files) {
                let pages = 0;
                for (const file of params.files) {
                    try {
                        if (file.type === "application/pdf") {
                            pages += await getNoOfPagesFromPDF(file);;
                        } else if (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || file.type === "application/msword") {
                            pages += await getNoOfPagesFromWord(file);
                        } else if (file.type === "text/plain") {
                            pages += await getNoOfPagesFromTxt(file);
                        } else {
                            pages += 1;
                        }
                    } catch (error) {
                        pages += 1;
                    }
                }

                setNoOfPages(pages);
            }
        }

        if (params && params.files) {
            fetchNumberOfPages();
        }

        return () => { }
    }, [params.files]);

    const defaultOptionsUploadSuccessful = {
        loop: false,
        autoplay: true,
        animationData: uploadSuccessfulData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const defaultOptionsLoading = {
        loop: true,
        autoplay: true,
        animationData: loadingData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <div className="bg-white sm:rounded-lg">
            <div className="">
                {!startedUpload &&
                    <>
                        <div className='col-span-full flex justify-center mt-5'>
                            <h3 className="text-2xl font-semibold leading-6 text-gray-900">Confirmation</h3>
                        </div>
                        <div className="col-span-full flex flex-col items-center justify-center mt-4 mb-6 text-base text-gray-500">
                            <p>
                                You will extract <strong>{params.fields.length}</strong> fields from <strong>{params.files.length}</strong> documents
                            </p>
                            <p>
                                Total number of pages: <strong>{noOfPages}</strong>
                            </p>
                        </div>
                    </>
                }

                {startedUpload && isFinished && !isError &&
                    <>
                        <div className="col-span-full flex justify-center items-center mt-2 text-sm text-gray-500">
                            <h3 className="text-2xl font-semibold leading-6 text-gray-900">Upload Successful</h3>
                            <Lottie
                                options={defaultOptionsUploadSuccessful}
                                style={{ margin: "0 0 0 10px" }}
                                height={48}
                                width={48}
                            />
                        </div>
                    </>
                }

                {startedUpload && isFinished && isError &&
                    <>
                        <div className="col-span-full flex justify-center items-center mt-2 text-sm text-gray-500">
                            <h3 className="text-2xl font-semibold leading-6 text-gray-900">Upload Failed</h3>
                        </div>
                    </>
                }

                {startedUpload && !isFinished &&
                    <div className="bg-white rounded-lg px-4 mt-10 ring-1 ring-slate-900/5 shadow-xl">
                        <FileList files={params.files} isUpload={true} percentages={percentages} />
                    </div>
                }

                {!startedUpload &&
                    <div className="col-span-full flex justify-center mt-4">
                        <button
                            onClick={() => previousStep()}
                            className="ml-4 inline-flex items-center rounded-md border hover:bg-gray-100 px-3 py-2 text-sm font-semibold text-gray-500 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                        >
                            Back
                        </button>
                        <button
                            onClick={() => createBatch()}
                            className="ml-4 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Upload
                        </button>
                    </div>
                }

                {startedUpload &&
                    <div className="col-span-full flex justify-center mt-4"></div>
                }

                {startedUpload && !isFinished &&
                    <div className="col-span-full flex justify-center mt-5 text-sm text-gray-500">
                        <Lottie
                            options={defaultOptionsLoading}
                            style={{ margin: "0 0 0 10px" }}
                            height={96}
                            width={96}
                        />
                    </div>
                }

                {startedUpload && isFinished && !isError &&
                    <div className="mt-5" >
                        <p className="text-center mt-2 text-sm text-gray-500">
                            Your batch has been uploaded successfully.
                        </p>
                        <p className="text-center mt-2 text-sm text-gray-500">
                            You can check the status of your extraction in the <a href="/extractions" className="text-indigo-600 hover:text-indigo-500">Extractions</a> page.
                        </p>
                    </div>
                }

                {startedUpload && isFinished && isError &&
                    <div className="mt-5" >
                        {errorMessage !== "" &&
                            <p className="text-center mt-2 mb-6 text-md text-gray-800">
                                {errorMessage}
                            </p>
                        }
                        <p className="text-center mt-2 text-sm text-gray-500">
                            There was an error uploading your batch. Please try again later.
                        </p>
                        <p className="text-center mt-2 text-sm text-gray-500">
                            If the problem persists, please contact us at <a href="https://extracta.ai/contact/" target="_blank" className="text-indigo-600 hover:text-indigo-500" rel="noreferrer">https://extracta.ai/contact/</a>.
                        </p>
                        <p className="text-center mt-2 text-sm text-gray-500">
                            We apologize for the inconvenience.
                        </p>
                    </div>
                }
            </div>
        </div>
    )
}
