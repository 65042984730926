
import React, { useCallback, useContext } from "react";
import app from "../utils/firebaseApp.js";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { AuthContext } from "../utils/auth.js";
import { withRouter } from "react-router";
import Notification from "../components/Notification.js";

function ForgotPassword() {
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [errorTitle, setErrorTitle] = React.useState("");
    const [errorSubtitle, setErrorSubtitle] = React.useState("");

    const resetError = () => {
        setError(false);
        setErrorTitle("");
        setErrorSubtitle("");
    }

    const handleForgotPassword = useCallback(
        async event => {
            event.preventDefault();
            const { email } = event.target.elements;
            try {
                const auth = getAuth(app);
                await sendPasswordResetEmail(auth, email.value);
                resetError();

                window.location.href = "/login";
            } catch (error) {
                setError(true);
                setErrorTitle("Error");
                setErrorSubtitle("Invalid email");

                setTimeout(() => {
                    resetError();
                }, 2000);
            }
        },
        [window.location]
    );

    const { currentUser } = useContext(AuthContext);

    if (currentUser) {
        window.location.href = "/";
    }

    // return the forgot password screen using the same layout as the login screen
    return (
        <div className="h-full bg-gray-50">
            <Notification show={error} setShow={setError} title={errorTitle} subtitle={errorSubtitle} />

            <div className="flex h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-10 w-auto"
                        src={process.env.PUBLIC_URL + '/logo.png'}
                        alt="Extracta.ai"
                    />
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                    <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                        <form className="space-y-6" onSubmit={handleForgotPassword}>
                            <h2 className="text-left text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                Reset password
                            </h2>
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Email address
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        required
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-ocerizare focus:border-ocerizare sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div>
                                <button
                                    className="mt-10 mb-2 flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Send password reset email
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                <p className="mt-10 text-center text-sm text-gray-500">
                    Password reset?{' '}
                    <a href="/login" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                        Return to sign in
                    </a>
                </p>
            </div>
        </div>
    );
}

export default withRouter(ForgotPassword);