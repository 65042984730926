import { useState, useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import app from "../utils/firebaseApp.js";

import MenuBar from '../components/MenuBar.js';
import ExtractionsList from './DataExtraction/ExtractionsList.js';
import ExtractionScreen from './DataExtraction/ExtractionScreen.js';
import BatchScreen from './DataExtraction/BatchScreen.js';
import TopBar from "../components/TopBar.js";

export default function DataExtraction() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleSignout = () => {
    getAuth(app).signOut(app).then(() => {
      console.log("Signout")
    }).catch((error) => {
      console.log(error)
    });
  }

  const { path } = useRouteMatch();

  useEffect(() => {
    const appName = process.env.REACT_APP_NAME;
    document.title = "Data Extraction - " + appName;
  }, []);

  return (
    <>
      <div>
        <MenuBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} handleSignout={handleSignout} currentHref="extractions" />

        <main className="lg:pl-72">
          <TopBar setSidebarOpen={setSidebarOpen} handleSignout={handleSignout} />

          <div className="py-10 px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col justify-center items-start">
              <div className='px-3'>
                <a href='/extractions' className="text-4xl font-bold text-gray-900">Data extraction</a>
                <p className="text-md mt-1 text-gray-500">Upload multiple documents to extract data from them</p>
              </div>

              <div className='w-full mt-10 px-3'>
                <Switch>
                  <Route exact path={path}>
                    <ExtractionsList />
                  </Route>
                  <Route exact path={`${path}/:extractionId`}>
                    <ExtractionScreen />
                  </Route>
                  <Route path={`${path}/:extractionId/batch/:batchId`}>
                    <BatchScreen />
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}