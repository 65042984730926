import React, { Fragment, useState, useRef, useEffect, useContext } from 'react'
import { AuthContext } from '../../utils/auth.js';
import axios from 'axios';

import { ArrowLeftIcon, Bars3Icon } from '@heroicons/react/20/solid';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function SecondStep({ nextStep, previousStep, parentParams, isEdit }) {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const environment = process.env.REACT_APP_ENV;

  // const initialInputs = parentParams?.fields?.map((fieldValue, index) => ({
  //   id: index,
  //   key: fieldValue.key,
  //   description: fieldValue.description,
  //   example: fieldValue.example,
  //   type: fieldValue.type ?? 'string',
  //   properties: fieldValue.properties.map((fieldValue2, index2) => ({
  //     id: index2,
  //     key: fieldValue2.key,
  //     description: fieldValue2.description,
  //     example: fieldValue2.example,
  //     type: fieldValue2.type ?? 'string',
  //   })),
  //   items: {
  //     type: fieldValue.items?.type,
  //     properties: fieldValue.items?.properties?.map((fieldValue2, index2) => ({
  //       id: index2,
  //       key: fieldValue2.key,
  //       description: fieldValue2.description,
  //       example: fieldValue2.example,
  //       type: fieldValue2.type ?? 'string',
  //     }))
  //   }
  // })) || [{ id: 0, key: '', description: '', example: '', type: 'string' }];

  const initialInputs = parentParams?.fields ? parentParams.fields.map((fieldValue, index) => ({
    id: index,
    key: fieldValue.key ?? '', // Fallback to an empty string if undefined
    description: fieldValue.description ?? '', // Fallback to an empty string if undefined
    example: fieldValue.example ?? '', // Fallback to an empty string if undefined
    type: fieldValue.type ?? 'string', // Use 'string' as the default type if undefined
    properties: fieldValue.properties ? fieldValue.properties.map((fieldValue2, index2) => ({
      id: index2,
      key: fieldValue2.key ?? '', // Fallback to an empty string if undefined
      description: fieldValue2.description ?? '', // Fallback to an empty string if undefined
      example: fieldValue2.example ?? '', // Fallback to an empty string if undefined
      type: fieldValue2.type ?? 'string', // Use 'string' as the default type if undefined
    })) : [], // Use an empty array if properties are undefined
    items: {
      type: fieldValue.items?.type ?? 'string', // Use 'string' as the default type if items or type is undefined
      example: fieldValue.items?.example ?? '', // Fallback to an empty string if undefined
      properties: fieldValue.items?.properties ? fieldValue.items.properties.map((fieldValue2, index2) => ({
        id: index2,
        key: fieldValue2.key ?? '', // Fallback to an empty string if undefined
        description: fieldValue2.description ?? '', // Fallback to an empty string if undefined
        example: fieldValue2.example ?? '', // Fallback to an empty string if undefined
        type: fieldValue2.type ?? 'string', // Use 'string' as the default type if undefined
      })) : [] // Use an empty array if items properties are undefined
    }
  })) : [{ id: 0, key: '', description: '', example: '', type: 'string', properties: [], items: { type: 'string', properties: [] } }]; // Default object structure if parentParams.fields is undefined


  const [inputs, setInputs] = useState(initialInputs);
  const formRef = useRef(null);

  const handleInputChange = (e, id, type) => {
    e.preventDefault();

    // Define the regex pattern for disallowed characters
    const disallowedCharsPattern = /[\.\$\#\[\]\/\x00-\x1F\x7F]/g;
    let sanitizedValue = e.target.value;

    if (type === 'key') {
      sanitizedValue = sanitizedValue.replace(disallowedCharsPattern, '');
    }

    setInputs(inputs.map((input) => {
      if (input.id === id) {
        switch (type) {
          case "key":
            input.key = sanitizedValue;
            break;
          case "description":
            input.description = sanitizedValue;
            break;
          case "example":
            if (input.type === 'array' && input.items.type === 'string') {
              input.items.example = sanitizedValue;
            } else {
              input.example = sanitizedValue;
            }
            break;
          default:
            break;
        }
      }
      return input;
    }));
  };

  const handleTypeChange = (type, id) => {
    setInputs(inputs.map((input) => {
      if (input.id === id) {
        input.type = type;

        if (type === 'string') {
          // do nothing
        } else if (type === 'object') {
          input.properties = [
            { id: 0, key: '', description: '', example: '', type: 'string' }
          ];
        } else if (type === 'list<string>') {
          input.type = 'array';
          input.items = {
            type: 'string',
          };
        } else if (type === 'list<object>') {
          input.type = 'array';
          input.items = {
            type: 'object',
            properties: [
              { id: 0, key: '', description: '', example: '', type: 'string' },
            ]
          };
        }
      }
      return input;
    }));
  };

  const handleChildrenInputChange = (e, id, type, parentId) => {
    e.preventDefault();

    // Define the regex pattern for disallowed characters
    const disallowedCharsPattern = /[\.\$\#\[\]\/\x00-\x1F\x7F]/g;
    let sanitizedValue = e.target.value;

    if (type === 'key') {
      sanitizedValue = sanitizedValue.replace(disallowedCharsPattern, '');
    }

    setInputs(inputs.map((input) => {
      if (input.id === parentId) {
        let parentType = input.type;

        if (parentType === 'object') {
          input.properties = input.properties.map((input2) => {
            if (input2.id === id) {
              switch (type) {
                case "key":
                  input2.key = sanitizedValue;
                  break;
                case "description":
                  input2.description = sanitizedValue;
                  break;
                case "example":
                  input2.example = sanitizedValue;
                  break;
                default:
                  break;
              }
            }
            return input2;
          });
        } else if (parentType === 'array') {
          input.items.properties = input.items.properties.map((input2) => {
            if (input2.id === id) {
              switch (type) {
                case "key":
                  input2.key = sanitizedValue;
                  break;
                case "description":
                  input2.description = sanitizedValue;
                  break;
                case "example":
                  input2.example = sanitizedValue;
                  break;
                default:
                  break;
              }
            }
            return input2;
          });
        }
      }
      return input;
    }));
  };

  const handleAddChildrenInput = (parentId) => {
    if (formRef.current) {
      formRef.current.reportValidity();
    }

    // here go to parentId input and add a new input to its properties or items.properties
    setInputs(inputs.map((input) => {
      if (input.id === parentId) {
        let parentType = input.type;

        if (parentType === 'object') {
          // check if the last input children is not empty
          if (input.properties[input.properties.length - 1].key && input.properties[input.properties.length - 1].description) {
            const newInputId = Date.now(); // using the current timestamp for a unique ID
            input.properties.push({ id: newInputId, key: '', description: '', example: '', type: 'string' });
          }
        } else if (parentType === 'array') {
          // check if the last input children is not empty
          if (input.items.properties[input.items.properties.length - 1].key && input.items.properties[input.items.properties.length - 1].description) {
            const newInputId = Date.now(); // using the current timestamp for a unique ID
            input.items.properties.push({ id: newInputId, key: '', description: '', example: '', type: 'string' });
          }
        }
      }
      return input;
    }));
  }

  const handleRemoveChildrenInput = (id, parentId) => {
    // here go to parent id and remove the child with the id
    setInputs(inputs.map((input) => {
      if (input.id === parentId) {
        let parentType = input.type;

        if (parentType === 'object') {
          input.properties = input.properties.filter((input2) => input2.id !== id);
        } else if (parentType === 'array') {
          input.items.properties = input.items.properties.filter((input2) => input2.id !== id);
        }
      }
      return input;
    }));
  }

  const handleAddInput = () => {
    if (formRef.current) {
      formRef.current.reportValidity();
    }

    const lastInput = inputs[inputs.length - 1];
    if (lastInput && lastInput.key && lastInput.description) { // Ensure last input exists and has a key
      let allChildrenHaveKeys = false; // Assume children keys might be empty

      const parentType = lastInput.type;
      if (parentType === 'object') { // Corrected case sensitivity
        allChildrenHaveKeys = lastInput.properties.every((input) => input.key && input.description);
      } else if (parentType === 'array') { // Corrected case sensitivity
        if (lastInput.items.type === 'object') {
          allChildrenHaveKeys = lastInput.items && lastInput.items.properties.every((input) => input.key && input.description);
        } else {
          allChildrenHaveKeys = true; // No children to check
        }
      } else {
        allChildrenHaveKeys = true; // No children to check
      }

      if (allChildrenHaveKeys) {
        const newInputId = Date.now(); // Unique ID for the new input
        setInputs([...inputs, { id: newInputId, key: '', description: '', example: '', type: 'string' }]);
      }
    }
  };


  const handleRemoveInput = (id) => {
    if (inputs.length === 1) {
      return; // Prevent removing the last input
    }
    const updatedInputs = inputs.filter((input) => input.id !== id);
    setInputs(updatedInputs);
  };

  const { currentUser } = useContext(AuthContext);

  const prepareFields = (fields) => {
    // console.log("====================================");
    // console.log(fields);
    // console.log("====================================");

    return fields.map((field) => {
      if (field.type === 'string') {
        delete field.properties;
        delete field.items;
      }

      if (field.type === 'array') {
        delete field.example;
        delete field.properties;
      }

      if (field.type === 'array' && field.items.type === 'string') {
        delete field.items.properties;
      }

      if (field.type === 'array' && field.items.type === 'object') {
        delete field.items.example
      }

      if (field.type === 'object') {
        delete field.example;
        delete field.items;
      }

      return field;
    });
  }

  const firstExtractionTask = async () => {
    await currentUser.getIdToken().then(async (idToken) => {
      const url = serverUrl + "/firstExtraction";

      await axios.post(url, {
        // nothing
      }, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${idToken}`
        }
      }).then((response) => {
        // nothing
      }).catch((error) => {
        // nothing
      });
    });
  }

  const createExtraction = async (params, callback) => {
    await currentUser.getIdToken().then(async (idToken) => {
      const url = serverUrl + "/createExtraction";

      await axios.post(url, {
        "extractionDetails": {
          "name": params.name,
          "description": params.description,
          "language": params.lang,
          "fields": params.fields,
          "options": params.options,
          "workflowId": params.workflowId,
          "createdAt": Date.now(),
        }
      }, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${idToken}`
        }
      }).then(async (response) => {
        if (params.extractionsLength === 0) {
          window.dataLayer.push({
            event: "extraction_created",
            extractionID: response.data.extractionID,
            extractionName: params.name,
            extractionLanguage: params.lang,
          });
          await firstExtractionTask();
        }
        callback(response.data.extractionId);
      }).catch((error) => {
        console.log(error);
      });
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isEdit) {
      let fields = inputs.map((input) => ({
        key: input.key,
        description: input.description,
        example: input.example,
        type: input.type,
        properties: input.properties,
        items: input.items,
      }));

      fields = prepareFields(fields);

      nextStep({ fields });
    } else {
      parentParams.fields = inputs.map((input) => ({
        key: input.key,
        description: input.description,
        example: input.example,
        type: input.type,
        properties: input.properties,
        items: input.items,
      }));

      parentParams.fields = prepareFields(parentParams.fields);

      // createExtraction(parentParams, (extractionId) => {
      //   nextStep({ closeModal: true, extractionId: extractionId });
      // });

      nextStep(parentParams);
    }
  }

  const handlePreviousStep = () => {
    let fields = inputs.map((input) => ({
      key: input.key,
      description: input.description,
      example: input.example,
      type: input.type,
      properties: input.properties,
      items: input.items,
    }));

    parentParams.fields = prepareFields(fields);

    previousStep(parentParams);
  }

  const [dragOverItem, setDragOverItem] = useState(null); // remains unchanged
  const [draggingItem, setDraggingItem] = useState(null); // remains unchanged
  const [dragItemIndex, setDragItemIndex] = useState(null);

  const dragItemRef = useRef();
  const dragNodeRef = useRef();

  const handleDragStart = (e, index) => {
    dragItemRef.current = index;
    dragNodeRef.current = e.target;
    dragNodeRef.current.addEventListener('dragend', handleDragEnd);

    setTimeout(() => {
      setDraggingItem(index);
    }, 0);

    setDragItemIndex(index); // store the index of the dragged item
  };

  const handleDragEnter = (e, targetItem) => {
    if (e.target !== dragNodeRef.current) {
      setDragOverItem(targetItem);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragEnd = () => {
    setDraggingItem(null);
    setDragItemIndex(null); // reset to default state
    dragNodeRef.current.removeEventListener('dragend', handleDragEnd);
    dragItemRef.current = null;
    dragNodeRef.current = null;
  };


  const handleDrop = (e) => {
    e.preventDefault();
    const newInputList = moveItem(inputs, dragItemIndex, dragOverItem);
    setInputs(newInputList);
    setDragOverItem(null); // reset after dropping
  };


  const moveItem = (list, from, to) => {
    const updatedList = [...list];
    const item = updatedList[from];
    updatedList.splice(from, 1);
    updatedList.splice(to, 0, item);
    return updatedList;
  };

  useEffect(() => {
    return () => {
      if (dragNodeRef.current) {
        dragNodeRef.current.removeEventListener('dragend', handleDragEnd);
      }
    };
  }, []);

  const descriptionsExample = [
    "extract the complete name of the person",
    "the age of the person in numbers",
    "the id / identification number / unique number or similar",
    "all the skills you find in the cv: hard and soft",
    "the date of the document, format it dd / mm / yyyy",
    "extract the languages as a list with “,” between",
    "the currency, interpret the symbol if found",
    "extract … only if it meets … criteria",
    "i want only … not …",
  ];

  return (
    <div className="bg-white sm:rounded-lg">
      <div className="">
        {/* back button */}
        <button
          onClick={() => handlePreviousStep()}
          className="mb-4 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <ArrowLeftIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
          Back
        </button>

        {/* title and subtitle */}
        <h3 className="text-base font-semibold leading-6 text-gray-900">Set Fields</h3>
        <div className="mt-1 w-full text-sm text-gray-500">
          <ul className="list-inside">
            <li><strong>Key</strong>: The name of the key that you want to extract.</li>
            <li><strong>Type</strong>: The type of information that you want to extract:
              <ul className="list-disc list-inside ml-4">
                <li>{"String: A simple string value. "}<em>{"Example: name of a person"}.</em></li>
                <li>{"Object: A unique object with multiple attributes. "}<em>{"Example: address of a person (city, street, number etc.)"}.</em></li>
                <li>{"List<String>: A list of simple string values. "}<em>{"Example: list of languages spoken by a person"}.</em></li>
                <li>{"List<Object>: A list of complex objects. "}<em>{"Example: list of products from an invoice, each containing item name, quantity, price"}.</em></li>
              </ul>
            </li>
            <li><strong>Description</strong>: Give details about what you want to extract. Explain as you are talking to a real person. This will help the AI understand your requirements better. You can also specify the format or give synonyms for the key.</li>
            <li><strong>Example</strong>: Provide an example for the key. Respect the format that you want to receive the data in.</li>
          </ul>
        </div>

        {/* form with keys */}
        <form className="mt-3 sm:flex sm:items-center" ref={formRef} onSubmit={handleSubmit}>
          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-5 sm:grid-cols-1 w-full">
            {/* inputs list */}
            {inputs.map((input, index) => (
              <InputItem
                key={input.id}
                input={input}
                index={index}
                handleInputChange={handleInputChange}
                handleTypeChange={handleTypeChange}
                handleRemoveInput={handleRemoveInput}
                handleDragStart={handleDragStart}
                handleDragEnter={handleDragEnter}
                handleDrop={handleDrop}
                draggingItem={draggingItem}
                isEdit={isEdit}
                inputs={inputs}
                // children
                handleChildrenInputChange={handleChildrenInputChange}
                handleAddChildrenInput={handleAddChildrenInput}
                handleRemoveChildrenInput={handleRemoveChildrenInput}
                isOnTop={input.isOnTop}
                descriptionsExample={descriptionsExample}
              />
            ))}

            {/* add new field button */}
            {true && (
              <div className='flex'>
                <button
                  onClick={handleAddInput}
                  type="button"
                  className="rounded-md bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                >
                  Add new field
                </button>
              </div>
            )}

            {/* spacer? */}
            <div></div>

            {/* next button */}
            <div className="sm:col-span-4">
              <div className="col-span-full flex justify-end w-full">
                <button
                  type="submit"
                  className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {isEdit ? 'Update extraction' : 'Next'}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

function InputItem({
  input,
  index,
  handleInputChange,
  handleTypeChange,
  handleRemoveInput,
  handleDragStart,
  handleDragEnter,
  handleDrop,
  draggingItem,
  isEdit,
  inputs,
  // children
  handleChildrenInputChange,
  handleAddChildrenInput,
  handleRemoveChildrenInput,
  isOnTop,
  descriptionsExample,
}) {
  const types = ['string', 'object', 'list<string>', 'list<object>'];

  // get the description from that list. do modulo to get the index
  const descriptionExample = descriptionsExample[index % descriptionsExample.length];

  // show only if is last element
  const showDescription = index === inputs.length - 1;

  return (
    <div
      key={input.id}
      draggable
      onDragStart={(e) => handleDragStart(e, index)}
      onDragEnter={draggingItem !== null ? (e) => handleDragEnter(e, index) : null}
      onDragOver={(e) => e.preventDefault()}
      onDrop={handleDrop}
      className={classNames(
        draggingItem === index ? 'dragging' : '',
        'sm:col-span-4 mb-0.5 px-4 bg-white rounded-lg border border-gray-200',
      )}
    >
      {/* place items on top center */}
      <div className={classNames(
        'flex justify-center',
        showDescription ? 'items-start pt-6 pb-3' : 'items-center py-5',
        ((input.type === 'array' && input.items.type === 'object') || input.type === 'object') ? (showDescription ? 'pb-1' : 'pb-3') : '',
      )}>
        {/* drag and drop button */}
        {inputs.length > 1 && (
          <button
            onMouseDown={(e) => handleDragStart(e, index)}
            className={classNames(
              'mr-2',
              showDescription ? 'mt-2.5' : '',
            )}
            title="Drag to reorder"
          >
            <Bars3Icon className="h-4 w-4 text-gray-400" aria-hidden="true" />
          </button>
        )}

        {/* isOnTop */}
        {isOnTop && (
          <div className="mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-indigo-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
            </svg>
          </div>
        )}

        {/* key */}
        <div className="relative w-full ">
          <label
            htmlFor="name"
            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
          >
            Key {index + 1}
          </label>
          <input
            onChange={(e) => handleInputChange(e, input.id, "key")}
            type="text"
            name="name"
            id={input.id}
            value={input.key}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="add key"
            required
          // disabled={isEdit}
          />
        </div>

        {/* type */}
        <div className="relative w-full ml-3">
          <label
            htmlFor="name"
            className="absolute -top-2 left-2 inline-block z-30 bg-white px-1 text-xs font-medium text-gray-900"
          >
            Type
          </label>
          <DropdownTypes types={types} type={input.type} input={input} handleChange={(type) => {
            handleTypeChange(type, input.id)
          }} />
        </div>

        {/* description */}
        <div className="relative w-full ml-3">
          <label
            htmlFor="name"
            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
          >
            Description
            {/* <span className='ml-1 text-xs font-normal text-gray-400'>
              {"(optional)"}
            </span> */}
          </label>
          <input
            onChange={(e) => handleInputChange(e, input.id, "description")}
            type="text"
            name="description"
            id={`${input.id}-description`}
            value={input.description}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="add description"
            required
          />
          {/* add with grey text an e.g. the name of the person */}
          {showDescription && (
            <div className='mt-1'>
              <span className="text-xs text-gray-400 inline-block overflow-hidden text-ellipsis line-clamp-2 leading-tight">
                e.g. {descriptionExample}
              </span>
            </div>
          )}
        </div>

        {/* example */}
        {(input.type !== 'object' && !(input.type === 'array' && input.items.type === 'object')) && (
          <div className="relative w-full ml-3">
            <label
              htmlFor="name"
              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
            >
              Example
              <span className='ml-1 text-xs font-normal text-gray-400'>
                {"(optional)"}
              </span>
            </label>
            {input.type === 'array' && input.items.type === 'string' ? (
              <input
                onChange={(e) => handleInputChange(e, input.id, "example")}
                type="text"
                name="example"
                id={`${input.id}-example`}
                value={input.items.example}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="add example"
              />) : (
              <input
                onChange={(e) => handleInputChange(e, input.id, "example")}
                type="text"
                name="example"
                id={`${input.id}-example`}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={input.example}
                placeholder="add example"
              />
            )}
          </div>
        )}

        {/* remove button */}
        {inputs.length > 1 && true && (
          <button
            onClick={() => handleRemoveInput(input.id)}
            className={classNames(
              'bg-red-400 hover:bg-red-500 w-4 h-4 flex items-center justify-center rounded-full ml-3 p-1',
              showDescription ? 'mt-2.5' : '',
            )}
            aria-label="Remove"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4" />
            </svg>
          </button>
        )}
      </div>

      {
        input.type === 'object' && (
          <div className='ml-8'>
            {input.properties.map((input2, index2) => (
              <InputItem2
                key={input2.id}
                parentId={input.id}
                input={input2}
                index={index2}
                isEdit={isEdit}
                inputs={input.properties}
                // children
                handleChildrenInputChange={handleChildrenInputChange}
                handleAddChildrenInput={handleAddChildrenInput}
                handleRemoveChildrenInput={handleRemoveChildrenInput}
              />
            ))}

            {true && (
              <div className='flex mt-2 mb-4'>
                <button
                  onClick={() => handleAddChildrenInput(input.id)}
                  type="button"
                  // className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-50"
                >
                  Add new child
                </button>
              </div>
            )}
          </div>
        )
      }

      {
        input.type === 'array' && input.items.type === 'object' && (
          <div className='ml-8'>
            {input.items.properties.map((input2, index2) => (
              <InputItem2
                key={input2.id}
                parentId={input.id}
                input={input2}
                index={index2}
                isEdit={isEdit}
                inputs={input.items.properties}
                // children
                handleChildrenInputChange={handleChildrenInputChange}
                handleAddChildrenInput={handleAddChildrenInput}
                handleRemoveChildrenInput={handleRemoveChildrenInput}
              />
            ))}

            {true && (
              <div className='flex mt-2 mb-4'>
                <button
                  onClick={() => handleAddChildrenInput(input.id)}
                  type="button"
                  className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-50"
                >
                  Add new child
                </button>
              </div>
            )}
          </div>
        )
      }
    </div >
  );
}

function InputItem2({
  parentId,
  input,
  index,
  isEdit,
  inputs,
  // children
  handleChildrenInputChange,
  handleAddChildrenInput,
  handleRemoveChildrenInput,
}) {
  const types2 = ['string'];

  return (
    <div
      key={input.id}
      className='sm:col-span-4 mb-0.5'
    >
      <div className='flex items-center'>
        {/* indicator */}
        <span
          className={classNames(
            'inline-block h-2 w-2 flex-shrink-0 rounded-full mr-3',
            true ? 'bg-indigo-500' : 'bg-gray-200'
          )}
          aria-hidden="true"
        />

        {/* key */}
        <div className="relative w-full ">
          <input
            onChange={(e) => handleChildrenInputChange(e, input.id, "key", parentId)}
            type="text"
            name="name"
            id={input.id}
            value={input.key}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="add key"
            required
          // disabled={isEdit}
          />
        </div>

        <div className="relative w-full ml-3">
          <DropdownTypes2 types={types2} type={input.type} input={input} handleChange={(type) => { }} />
        </div>

        {/* description */}
        <div className="relative w-full ml-3">
          <input
            onChange={(e) => handleChildrenInputChange(e, input.id, "description", parentId)}
            type="text"
            name="description"
            id={`${input.id}-description`}
            value={input.description}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="add description"
            required
          />
        </div>

        {/* example */}
        <div className="relative w-full ml-3">
          <input
            onChange={(e) => handleChildrenInputChange(e, input.id, "example", parentId)}
            type="text"
            name="example"
            id={`${input.id}-example`}
            value={input.example}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="add example"
          />
        </div>

        {/* remove button */}
        {inputs.length > 1 && true && (
          <button
            onClick={() => handleRemoveChildrenInput(input.id, parentId)}
            className="bg-red-400 hover:bg-red-500 w-4 h-4 flex items-center justify-center rounded-full ml-3 p-1"
            aria-label="Remove"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4" />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
}

function DropdownTypes({ types, type, input, handleChange }) {
  const handleChangeLocal = (type) => {
    handleChange(type);
  }

  const handleDifferentTypeFormats = (type) => {
    if (type === 'array') {
      if (input.items.type === 'string') {
        return 'list<string>';
      } else if (input.items.type === 'object') {
        return 'list<object>';
      }
    } else {
      return type;
    }
  }

  return (
    <Listbox value={type} onChange={handleChangeLocal}>
      {({ open }) => (
        <>
          {/* <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Assigned to</Listbox.Label> */}
          <div className="relative">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="block truncate">{handleDifferentTypeFormats(type)}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {types.map((person) => (
                  <Listbox.Option
                    key={person}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-8 pr-4'
                      )
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {person}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 left-0 flex items-center pl-1.5'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

function DropdownTypes2({ types, type, handleChange }) {
  const handleChangeLocal = (type) => {
    handleChange(type);
  }

  return (
    <Listbox value={type} onChange={handleChangeLocal}>
      {({ open }) => (
        <>
          {/* <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Assigned to</Listbox.Label> */}
          <div className="relative">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="block truncate">{type}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {types.map((person) => (
                  <Listbox.Option
                    key={person}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-8 pr-4'
                      )
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {person}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 left-0 flex items-center pl-1.5'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

export default SecondStep
