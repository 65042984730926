/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useLayoutEffect, useRef, useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom';
import { Menu, Dialog, Transition } from '@headlessui/react'
import { get, ref, onValue, startAt, orderByKey, limitToFirst, query } from "firebase/database";
import axios from "axios";
import ReactJson from 'react18-json-view';
import 'react18-json-view/src/style.css'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ArrowLongLeftIcon, ArrowLongRightIcon, ChevronDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel, faFileCsv, faCode, faRefresh, faEdit as IconEdit, faFileExport } from '@fortawesome/free-solid-svg-icons'
import { PlusIcon, TrashIcon } from '@heroicons/react/20/solid'
import { CalendarIcon, DocumentTextIcon, GlobeAltIcon, AdjustmentsHorizontalIcon, CodeBracketIcon, CodeBracketIcon as CustomCodeIcon } from '@heroicons/react/24/outline';
import { PencilIcon } from '@heroicons/react/20/solid'

import { AuthContext } from "../../../utils/auth.js";
import CreateExtraction from '../../../components/CreateExtraction.js';
import ViewFile from '../../../components/ViewFile.js';
import ModalForNewExtraction from '../../../components/utils/ModalForNewExtraction.js';
import ModalForViewFile from '../../../components/utils/ModalForViewFile.js';
import { handleGenerateJson, handleGenerateExcel, handleGenerateCsv } from '../../../components/functions/exportData.js';
import { db } from '../../../utils/firebaseApp.js';
import Breadcrumb from '../../../components/Breadcrumb';

import { Portal } from 'react-portal'; // Import Portal directly

function classNames(...classes) { return classes.filter(Boolean).join(' ') }

function DropdownWithIcons({ initialButton, file, exportAs }) {
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
    const buttonRef = useRef(null);

    const handleButtonClick = () => {
        if (buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            setMenuPosition({
                left: rect.left - 120,
                top: rect.bottom
            });
        }
    };

    return (
        <Menu as="div" className="relative inline-block text-left overflow-visible">
            <div onClick={handleButtonClick} ref={buttonRef} className='z-50'>
                {initialButton}
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    style={{
                        left: `${menuPosition.left}px`,
                        top: `${menuPosition.top}px`
                    }}
                    className="fixed right-0 md:right-10 z-100 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                    <div className="py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    onClick={() => exportAs('excel')}
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'cursor-pointer group flex items-center px-4 py-2 text-sm'
                                    )}
                                >
                                    <FontAwesomeIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' icon={faFileExcel} />
                                    Export as Excel
                                </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    onClick={() => exportAs('csv')}
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'cursor-pointer group flex items-center px-4 py-2 text-sm'
                                    )}
                                >
                                    <FontAwesomeIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' icon={faFileCsv} />
                                    Export as CSV
                                </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    onClick={() => exportAs('json')}
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'cursor-pointer group flex items-center px-4 py-2 text-sm'
                                    )}
                                >
                                    <FontAwesomeIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' icon={faCode} />
                                    Export as JSON
                                </a>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

function DropdownWithIconsOnTop({ initialButton, file, exportAs }) {
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
    const buttonRef = useRef(null);

    const handleButtonClick = () => {
        if (buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            setMenuPosition({
                left: rect.left - 120,
                top: rect.bottom
            });
        }
    };

    return (
        <Menu as="div" className="relative inline-block text-left overflow-visible">
            <div onClick={handleButtonClick} ref={buttonRef} className='z-100'>
                {initialButton}
            </div>

            <Portal id="dropdown-container">
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        style={{
                            left: `${menuPosition.left}px`,
                            top: `${menuPosition.top}px`
                        }}
                        className="fixed right-0 md:right-10 z-100 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                        <div className="py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        onClick={() => exportAs('excel')}
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'cursor-pointer group flex items-center px-4 py-2 text-sm'
                                        )}
                                    >
                                        <FontAwesomeIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' icon={faFileExcel} />
                                        Export as Excel
                                    </a>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        onClick={() => exportAs('csv')}
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'cursor-pointer group flex items-center px-4 py-2 text-sm'
                                        )}
                                    >
                                        <FontAwesomeIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' icon={faFileCsv} />
                                        Export as CSV
                                    </a>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        onClick={() => exportAs('json')}
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'cursor-pointer group flex items-center px-4 py-2 text-sm'
                                        )}
                                    >
                                        <FontAwesomeIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' icon={faCode} />
                                        Export as JSON
                                    </a>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Portal>
        </Menu>
    )
}

function DropdownWithIconsOnTopOld({ initialButton, file, exportAs }) {
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
    const buttonRef = useRef(null);

    const handleButtonClick = () => {
        if (buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            setMenuPosition({
                left: rect.left - 120,
                top: rect.bottom
            });
        }
    };

    return (
        <Menu as="div" className="relative inline-block text-left overflow-visible">
            <div onClick={handleButtonClick} ref={buttonRef} className='z-100'>
                {initialButton}
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    style={{
                        left: `${menuPosition.left}px`,
                        top: `${menuPosition.top}px`
                    }}
                    className="fixed right-0 md:right-10 z-100 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                    <div className="py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    onClick={() => exportAs('excel')}
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'cursor-pointer group flex items-center px-4 py-2 text-sm'
                                    )}
                                >
                                    <FontAwesomeIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' icon={faFileExcel} />
                                    Export as Excel
                                </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    onClick={() => exportAs('csv')}
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'cursor-pointer group flex items-center px-4 py-2 text-sm'
                                    )}
                                >
                                    <FontAwesomeIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' icon={faFileCsv} />
                                    Export as CSV
                                </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    onClick={() => exportAs('json')}
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'cursor-pointer group flex items-center px-4 py-2 text-sm'
                                    )}
                                >
                                    <FontAwesomeIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' icon={faCode} />
                                    Export as JSON
                                </a>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

function DropdownWithDividerAndIcons({ initialButton, file, exportAs, reloadFile, deleteFile }) {
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
    const buttonRef = useRef(null);

    const handleButtonClick = () => {
        if (buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            setMenuPosition({
                left: rect.left - 200,
                top: rect.bottom
            });
        }
    };

    return (
        <Menu as="div" className="relative inline text-left overflow-visible">
            <div onClick={handleButtonClick} ref={buttonRef} className='z-50'>
                {initialButton}
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    style={{
                        left: `${menuPosition.left}px`,
                        top: `${menuPosition.top}px`
                    }}
                    className="fixed right-0 md:right-10 z-100 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                    <div className="py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    onClick={() => exportAs()}
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'cursor-pointer group flex items-center px-4 py-2 text-sm'
                                    )}
                                >
                                    <FontAwesomeIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' icon={faFileExport} />
                                    Export File
                                </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    onClick={() => reloadFile({ fileId: file.id })}
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'cursor-pointer group flex items-center px-4 py-2 text-sm'
                                    )}
                                >
                                    <FontAwesomeIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' icon={faRefresh} />
                                    Redo File
                                </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    onClick={() => deleteFile({ fileId: file.id })}
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'cursor-pointer group flex items-center px-4 py-2 text-sm'
                                    )}
                                >
                                    <TrashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                    Delete File
                                </a>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export { DropdownWithIcons, DropdownWithDividerAndIcons, DropdownWithIconsOnTop };