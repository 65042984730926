/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import 'react18-json-view/src/style.css'
import 'react-loading-skeleton/dist/skeleton.css';
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid';

function Pagination({ numberOfPages, currentPageNumber, previousButton, nextButton, selectPage, itemsPerPage, setItemsPerPage }) {
    const renderPageNumbers = () => {
        const pageNumbers = [];

        // Display up to 3 page numbers on either side of current page, with "..." ellipses for gaps
        for (let i = 1; i <= numberOfPages; i++) {
            if (i === 1 || i === numberOfPages || (i >= currentPageNumber - 2 && i <= currentPageNumber + 2)) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => selectPage(i)}
                        className={`inline-flex items-center border-t-2 ${i === currentPageNumber
                            ? "border-indigo-500 text-indigo-600"
                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                            } px-4 pt-4 text-sm font-medium`}
                        aria-current={i === currentPageNumber ? "page" : undefined}
                    >
                        {i}
                    </button>
                );
            } else if (i < currentPageNumber - 2) {
                if (pageNumbers[pageNumbers.length - 1] !== '...') {
                    pageNumbers.push('...');
                }
            } else if (i > currentPageNumber + 2) {
                if (pageNumbers[pageNumbers.length - 1] !== '...') {
                    pageNumbers.push('...');
                }
            }
        }

        // iterate over pageNumbers array and when the value is '...', replace it with a span containing an ellipsis
        for (let i = 0; i < pageNumbers.length; i++) {
            if (pageNumbers[i] === '...') {
                pageNumbers[i] = <span key={i + i} className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">...</span>
            }
        }

        return pageNumbers;
    };

    return (
        <nav className="mt-4 flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
            <div className="-mt-px flex w-0 flex-1">
                <button
                    onClick={previousButton}
                    disabled={currentPageNumber === 1}
                    className={`inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium ${currentPageNumber === 1 ? "text-gray-300" : "text-gray-500 hover:border-gray-300 hover:text-gray-700"}`}
                    aria-disabled={currentPageNumber === 1}
                >
                    <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                    Previous
                </button>
            </div>
            <div className="hidden md:-mt-px md:flex">
                {renderPageNumbers()}
            </div>
            <div className="-mt-px flex w-0 flex-1 justify-end">
                <button
                    onClick={nextButton}
                    disabled={currentPageNumber === numberOfPages || numberOfPages === 0}
                    className={`inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium ${(currentPageNumber === numberOfPages || numberOfPages === 0) ? "text-gray-300" : "text-gray-500 hover:border-gray-300 hover:text-gray-700"}`}
                    aria-disabled={currentPageNumber === numberOfPages || numberOfPages === 0}
                >
                    Next
                    <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                </button>
            </div>
            <select
                value={itemsPerPage}
                onChange={(e) => setItemsPerPage(Number(e.target.value))}
                className="ml-5 mt-5 inline-block py-1 pr-7 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-sm"
            >
                <option value={10}>10 per page</option>
                <option value={20}>20 per page</option>
                <option value={50}>50 per page</option>
                <option value={100}>100 per page</option>
            </select>
        </nav>
    );
}

export default Pagination; 