/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import 'react18-json-view/src/style.css'
import 'react-loading-skeleton/dist/skeleton.css';
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid';

function PaginationAdmin({ numberOfPages, currentPageNumber, previousButton, nextButton, selectPage, itemsPerPage, setItemsPerPage }) {
    return (
        <nav className="mt-2 flex items-center justify-between px-4 sm:px-0">
            <div className="-mt-px flex w-0 flex-1">
                <button
                    onClick={previousButton}
                    disabled={currentPageNumber === 1}
                    className={`inline-flex items-center pr-1 pt-4 text-sm font-medium ${currentPageNumber === 1 ? "text-gray-300" : "text-gray-500 hover:text-gray-700"}`}
                    aria-disabled={currentPageNumber === 1}
                >
                    <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                    Previous
                </button>
            </div>
            <div className="hidden md:-mt-px md:flex">
                <span className="inline-flex items-center px-4 pt-4 text-sm font-medium text-gray-500">Page {currentPageNumber} of {numberOfPages}</span>
            </div>
            <div className="-mt-px flex w-0 flex-1 justify-end">
                <button
                    onClick={nextButton}
                    disabled={currentPageNumber === numberOfPages || numberOfPages === 0}
                    className={`inline-flex items-center pl-1 pt-4 text-sm font-medium ${(currentPageNumber === numberOfPages || numberOfPages === 0) ? "text-gray-300" : "text-gray-500 hover:text-gray-700"}`}
                    aria-disabled={currentPageNumber === numberOfPages || numberOfPages === 0}
                >
                    Next
                    <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                </button>
            </div>
        </nav>
    );
}

export default PaginationAdmin; 